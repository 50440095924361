import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-heading-text',
  templateUrl: './heading-text.component.html',
  styleUrls: ['./heading-text.component.scss']
})
export class HeadingTextComponent implements OnInit {

  @Input() public text = '' as string;
  @Input() public desc = '' as string;
  @Input() public extraStyle = '' as string;
  @Input() public extraCSSClass = '' as string;

  constructor() { }

  ngOnInit() {
  }

}
