import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {API} from '../../config/api.constants';
import {ApiDelegate} from '../../core/http/api.delegate';

@Injectable({
  providedIn: 'root'
})
export class MessageService {


  constructor(private apiDelegate: ApiDelegate) {
  }

  setMessage(body) {
    return this.apiDelegate.post(API.SEND_MESSAGE.url, body, this.getToken())
      .pipe(map(res => res));
  }


  getMessage() {
    return this.apiDelegate.get(API.SEND_MESSAGE.url, this.getToken())
      .pipe(map(res => res));
  }

  getVideo() {
    return this.apiDelegate.getFile(API.GET_VIDEO.url, this.getToken())
      .pipe(map(res => res));
  }

  uploadVideo(body) {
    return this.apiDelegate.post(API.UPLOAD_VIDEO.url, body, this.getToken())
      .pipe(map(res => res));
  }

  uploadCsvFile(body){
    return this.apiDelegate.upload(API.UPLOAD_CSV_FILE.url, body).pipe(map(res => res));
  }

  private getToken() {
    return `${localStorage.getItem('token')}`;
  }

}
