import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth-guard.service';
import { RouteGuard } from './core/guards/route-guard.service';
import { ResetPasswordComponent } from './views/access/reset-password/reset-password.component';
import { ExecutorRejectionComponent } from './views/access/executor-rejection/executor-rejection.component';
import { PrivacyComponent } from './views/home/policy/privacy/privacy.component';
import { CookieComponent } from './views/home/policy/cookie/cookie.component';
import {PreviewComponent} from './views/preview/preview.component';
import { FaqComponent } from './views/home/faq/faq.component';
import {AccountActivationComponent} from './views/account-activation/account-activation.component';
import { BulkUsersComponent } from './views/bulk-users/bulk-users.component';
 
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'access', loadChildren: './views/access/access-view.module#AccessViewModule', canActivate: [RouteGuard] },
  { path: 'home', loadChildren: './views/home/home-view.module#HomeViewModule' },
  { path: 'message', loadChildren: './views/message/message-view.module#MessageViewModule', canActivate: [AuthGuard] },
  { path: 'payment', loadChildren: './views/payment/payment-view.module#PaymentViewModule', canActivate: [AuthGuard] },
  { path: 'panel', loadChildren: './views/panel/panel-view.module#PanelViewModule', canActivate: [AuthGuard] },
  { path: 'bulk-users', component: BulkUsersComponent},

  { path: 'profile', loadChildren: './views/profile/profile-view.module#ProfileViewModule', canActivate: [AuthGuard] },
  { path: 'delegate', loadChildren: './views/delegate/delegate-view.module#DelegateViewModule', canActivate: [AuthGuard] },
  { path: 'password-forgotten', component: ResetPasswordComponent, canActivate: [] },
  { path: 'executor-rejection', component: ExecutorRejectionComponent, canActivate: [] },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'cookie', component: CookieComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'account-activation', component: AccountActivationComponent },

  { path: 'preview/:id', component: PreviewComponent },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }



