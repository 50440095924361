import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccessService} from '../_services/access.service';

declare var $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public form: FormGroup;
  public errorMessage = '' as string;
  public showPopup = false as boolean;
  public message = '';
  code: string;

  constructor(private route: ActivatedRoute, private fb: FormBuilder,
              private accessService: AccessService, private router: Router) {
    this.form = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => this.code = params['verify']);
  }


  public submit({value, valid}: { value: any, valid: boolean }) {
    this.errorMessage = '';
    const body = {
      code: this.code,
      password: value.password
    };
    if (valid) {
      this.accessService.resetPassword(body)
        .subscribe(
          res => {
            this.openPopup('The password has been successfully changed');
          },
          err => {
            if (err && err.data && err.data.error) {
              this.errorMessage = err.data.error.error;
            } else {
              this.errorMessage = 'Something went wrong, please try again.';
            }
          }
        );
    } else if (value.password !== value.confirmPassword) {
      this.errorMessage = 'Passwords do not match';
    } else {
      this.errorMessage = 'Please fill in the form';
    }
  }

  public openPopup(message) {
    this.message = message;
    this.showPopup = true;
    setTimeout(_ => {
      this.showPopup = false;
      this.router.navigate(['/access/login']);
    }, 3000);
  }

}
