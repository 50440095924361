import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Output()
  clickEvent = new EventEmitter();

  constructor() {
  }

  @HostListener('click', ['$event'])
  oncClick(event: MouseEvent) {
    if (event.srcElement.localName === 'app-confirm') {
      this.clickEvent.emit();
    }
  }

  ngOnInit() {
  }

}
