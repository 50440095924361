import {Injectable} from '@angular/core';
import {ApiDelegate} from './../http/api.delegate';
import {API} from './../../config/api.constants';
import {of, Observable, BehaviorSubject, timer} from 'rxjs';
import {delay, map, share, switchMap, tap, mapTo} from 'rxjs/internal/operators';
import {LoginModel} from '../../shared/models/login.model';
import {RegisterModel} from '../../shared/models/register.model';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  authSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.hasToken());
  dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>({type: 'none', data: null});
  dataObject = this.dataSubject.asObservable();

  userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  userObject = this.userSubject.asObservable();


  constructor(private apiDelegate: ApiDelegate, private router: Router) {
  }

  public isLoggedIn(): Observable<boolean> {
    return this.authSubject.asObservable().pipe(share());
  }

  public getUserData(): Observable<any> {
    return this.userSubject.asObservable().pipe(share());
  }

  login(body: LoginModel) {
    this.apiDelegate.post(API.LOGIN.url, body, '').pipe(
      // 'switchMap' operator is necessary to switch from the 1st Observable to another one
      switchMap((resData: any) => {
        if (resData.token) {
          this.authSubject.next(true);
          localStorage.setItem('token', resData.token);
          return this.apiDelegate.get(API.USER_STATUS.url, resData.token)
            .pipe(map(data => new Object({isChangePasswrod: false, userData: data})));
        } else if (resData['pwd-change']) {
          // 'of' operator is necessary to create a new observable
          return of(new Object({change: true, token: resData['pwd-change']}));
        }
      }),
      // 'map' is a simple operator that can transform or replace the returned value ( or values ) of an Observable
      map((responseFlatten: any) => {
        // console.log('Response flatten', responseFlatten);
        let routeToFollow: string;
        if (responseFlatten.change) {
          routeToFollow = '/access/reset-temp-password';
        } else if (!responseFlatten.isChangePassword) {

          localStorage.setItem('user', JSON.stringify(responseFlatten.userData));
          this.authSubject.next(true);
          this.userSubject.next(responseFlatten.userData);

          if (responseFlatten.userData.users_to_execute && responseFlatten.userData.users_to_execute.length > 0) {
            let hasToExecute = false;
            responseFlatten.userData.users_to_execute.forEach(item => {
              if (!item.status || item.status === 'pending') {
                hasToExecute = true;
              }
            });
            routeToFollow = hasToExecute ? '/delegate' : '/profile';
          } else {

            if (responseFlatten.userData &&
              !responseFlatten.userData.plan &&
              !responseFlatten.userData.text_saved &&
              !responseFlatten.userData.video_uploaded &&
              !responseFlatten.userData.contatcs_selected &&
              !responseFlatten.userData.executor_chosen) {
              routeToFollow = '/panel/tutorial';
            } else if (!responseFlatten.userData.text_saved) {
              routeToFollow = '/message/text';
            } else if (!responseFlatten.userData.video_uploaded && responseFlatten.userData.plan && responseFlatten.userData.plan.id > 2 && (!responseFlatten.userData.calculated_amount || parseFloat(responseFlatten.userData.calculated_amount) === 0)) {
              routeToFollow = '/message/video';
            } else if (!responseFlatten.userData.contatcs_selected) {
              routeToFollow = '/message/contacts';
            } else if (!responseFlatten.userData.executor_chosen) {
              routeToFollow = '/message/delegate';
            } 
            // else if ((!responseFlatten.userData.payment && responseFlatten.userData.plan.id > 1) || (responseFlatten.userData.calculated_amount && parseFloat(responseFlatten.userData.calculated_amount) > 0)) {
            //   routeToFollow = '/payment';
            // } 
            else if (responseFlatten.userData && responseFlatten.userData.plan) {
  
              routeToFollow = '/profile';
            } else {
              routeToFollow = '/panel/tutorial';
            }
          }
        }
        return routeToFollow;
      })
    ).subscribe(
      (routeToFollow: string) => {
        // console.log('Route to follow', routeToFollow);
        this.router.navigate([routeToFollow]);
      },
      err => {
        this.dataSubject.next({type: 'login', data: err});
      });
  }


  getUserStatus() {
    return this.apiDelegate.get(API.USER_STATUS.url, this.getToken())
      .pipe(map((res) => {
        this.userSubject.next(res);
        localStorage.setItem('user', JSON.stringify(res));
        return res;
      }));
  }

  register(body: RegisterModel): any {
    return this.apiDelegate.post(API.REGISTER.url, body, '')
      .pipe(map(res => res));
  }

  checkVerification(body): any {
    return this.apiDelegate.post(API.CODE_VERIFICATION.url, body, '')
      .pipe(map(res => res));

  }

  activateAndChangePassword(body): any {
    return this.apiDelegate.post(API.ACTIVATE_AND_CHANGE_PASSWORD.url, body, '')
      .pipe(map(res => res));
  }

  asyncLogin(): Observable<number> {
    return of(200).pipe(delay(2000));
  }

  private getToken() {
    return `${localStorage.getItem('token')}`;
  }

  private hasToken(): boolean {
    return !!localStorage.getItem('token');
  }


}
