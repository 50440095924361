import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {SharedModule} from './shared/shared.module';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {AccessViewModule} from './views/access/access-view.module';
import {ResetPasswordComponent} from './views/access/reset-password/reset-password.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ExecutorRejectionComponent} from './views/access/executor-rejection/executor-rejection.component';
import {PrivacyComponent} from './views/home/policy/privacy/privacy.component';
import {CookieComponent} from './views/home/policy/cookie/cookie.component';
import {PreviewComponent} from './views/preview/preview.component';
import {FaqComponent} from './views/home/faq/faq.component';
import {AccountActivationComponent} from './views/account-activation/account-activation.component';
import {BulkUsersComponent} from './views/bulk-users/bulk-users.component'


@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordComponent,
    ExecutorRejectionComponent,
    PrivacyComponent,
    CookieComponent,
    PreviewComponent,
    FaqComponent,
    AccountActivationComponent,
    BulkUsersComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AccessViewModule
  ],
  exports: [SharedModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
