import {Injectable} from '@angular/core';
import {ApiDelegate} from '../../../core/http/api.delegate';
import {API} from '../../../config/api.constants';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PanelService {


  constructor(private apiDelegate: ApiDelegate) {
  }

  public getPlanList() {
    return this.apiDelegate.get(API.PAYMENT_PLAN.url, this.getToken())
      .pipe(map(res => res));

  }

  public setPlan(body) {
    return this.apiDelegate.post(API.USER_PLAN.url, body, this.getToken())
      .pipe(map(res => res));

  }

  private getToken() {
    return `${localStorage.getItem('token')}`;
  }

}
