import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {API} from '../../config/api.constants';
import {ApiDelegate} from '../../core/http/api.delegate';

@Injectable({
  providedIn: 'root'
})
export class PreviewService {


  constructor(private apiDelegate: ApiDelegate) {
  }

  getMessage(id) {
    return this.apiDelegate.get(API.USER_MESSAGE.url + '/' + id, '')
      .pipe(map(res => res));
  }

  getVideo(id) {
    return this.apiDelegate.getFile(API.USER_VIDEO.url +  '/' + id, '')
      .pipe(map(res => res));
  }


}
