import {Injectable} from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {

  constructor(private authService: AuthenticationService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.authService.getUserData()
      .pipe(
        take(1),
        map((user: any) => {
          if (!user) {
            if (state.url.match('/access/login') ||
              state.url.match('/access/register') ||
              state.url.match('/access/forgot-password') ||
              state.url.match('/access/reset-password') ||
              state.url.match('/access/reset-temp-password')) {
              return true;
            } else {
              this.router.navigate(['/access/login']);
              return false;
            }


          } else {

            if (state.url === '/access/login' || state.url === '/access/register' || state.url === '/access/forgot-password') {
       
              this.router.navigate(['/profile']);
            }
            return true;

          }
        }));

  }


}
