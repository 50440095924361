import { Injectable } from '@angular/core';
import { ApiDelegate } from '../../../core/http/api.delegate';
import { API } from '../../../config/api.constants';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  constructor(private apiDelegate: ApiDelegate) { }

  public getResetLink(body) {
    return this.apiDelegate.post(API.PASSWORD_FORGOTTEN.url, body, this.getToken())
      .pipe(map(res => res));
  }

  public resetPassword(body) {
    return this.apiDelegate.post(API.RESET_PASSWORD.url, body, this.getToken())
      .pipe(map(res => res));
  }

  public resetTempPassword(body) {
    return this.apiDelegate.post(API.PASSWORD_CHANGE.url, body, this.getToken())
      .pipe(map(res => res));
  }


  public rejectExecution(code) {
    return this.apiDelegate.get(API.EXECUTOR_REJECTION.url + code, this.getToken())
      .pipe(map(res => res));
  }

  private getToken() {
    return `${localStorage.getItem('token')}`;
  }
}

