import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Routes, RouterModule} from '@angular/router';

import {AccessViewComponent} from './access-view.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetTempPasswordComponent} from './reset-temp-password/reset-temp-password.component';

const routes: Routes = [
  {
    path: '',
    component: AccessViewComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'reset-temp-password',
        component: ResetTempPasswordComponent
      }
    ]
  }
];

@NgModule({
  declarations: [AccessViewComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetTempPasswordComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ]
})
export class AccessViewModule {
}
