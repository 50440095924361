import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {PanelService} from '../../../views/panel/_services/panel.service';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isLoggedIn = false as boolean;
  public user = null;
  public logoUrl = 'assets/images/ps_logo.png';
  public logoMUrl = 'assets/images/ps_logo_mobile.png';
  public isOutline = false;
  public plan = null;
  public planList = [];

  constructor(private authService: AuthenticationService,
              private panelService: PanelService,
              private router: Router) {
    if (location.pathname === '/home' || location.pathname.match('/home?verify') || location.pathname === '/panel/tutorial') {
      this.logoUrl = 'assets/images/logo-white.png';
      this.logoMUrl = 'assets/images/logo-white-m.png';
      this.isOutline = true;
    } else {
      this.logoUrl = 'assets/images/ps_logo.png';
      this.logoMUrl = 'assets/images/ps_logo_mobile.png';
      this.isOutline = false;
    }
  }

  ngOnInit() {

    this.authService.getUserData()
      .subscribe(
        data => {
          this.user = data;
        }
      );

    this.panelService.getPlanList()
      .subscribe(
        (res: any) => {
          this.planList = res;
          this.authService.getUserData()
            .subscribe(
              data => {
                this.user = data;
                if (data && this.planList.length > 0) {
                  this.plan = this.planList[this.user.purchased_plan - 1];
                } else {
                  this.plan = null;
                }
              }
            );
        }
      );

    this.router.events
      .subscribe(
        res => {
          if (res instanceof NavigationEnd) {
            let route = '';
            if (res.url.match('/panel/tutorial') || res.url.includes('/home') || res.url === '/' ) {
              this.logoUrl = 'assets/images/logo-white.png';
              this.logoMUrl = 'assets/images/logo-white-m.png';
              this.isOutline = true;
            } else {
              this.logoUrl = 'assets/images/ps_logo.png';
              this.logoMUrl = 'assets/images/ps_logo_mobile.png';
              this.isOutline = false;
            }

            if (res.url === '/home' ||
              res.url.match('/panel/pricing') ||
              res.url.match('/access/register') ||
              res.url.match('/access/login')) {
              route = '';
            } else if (this.user && this.user.users_to_execute.length > 0) {
              let hasToExecute = false;

              this.user.users_to_execute.forEach(item => {
                if (!item.status || item.status === 'pending') {
                  hasToExecute = true;
                }
              });


              if (hasToExecute) {
                // route = '/delegate';
              }
              if (res.url === '/delegate/confirm-process') {
                route = res.url;
              }
              // alert(`route ${route}`)

            } else {
              // alert(route)
              if (this.user &&
                !this.user.plan &&
                !this.user.text_saved &&
                !this.user.video_uploaded &&
                !this.user.contatcs_selected &&
                !this.user.executor_chosen) {
                route = '/panel/tutorial';
              } else if (this.user && !this.user.error) {
                if (!this.user.text_saved) {
                  route = '/message/text';
                } else if (!this.user.video_uploaded && this.user.plan && this.user.plan.id > 2 && (parseFloat(this.user.calculated_amount) === 0 || !this.user.calculated_amount) && !localStorage.getItem('skipVideo')) {
                  route = '/message/video';
                  // if (res.url.match('editMode') || res.url.match('payment')) {
                  //   route = '';
                  // }

                } else if (!this.user.contatcs_selected) {
                  route = '/message/contacts';
                } else if (!this.user.executor_chosen) {
                  route = '/message/delegate';
                } 
                // else if (this.user.payment && res.url === '/profile' && this.user.purchased_plan) {
                //   route = '';
                // } 
                // else if ((!this.user.payment && this.user.plan.id > 1) || (this.user.purchased_plan && this.user.purchased_plan !== this.user.plan.id)) {
                //   route = '/profile';

                //   if (res.url.match('viewMode')) {
                //     route = '';
                //   }
                // }
              }
            }


            if (route) {
              this.router.navigate([route]);
            }


          }
        }
      );


    this.authService.getUserData()
      .subscribe(
        data => {
          this.user = data;
          if (data && this.planList.length > 0) {
            this.plan = this.planList[this.user.purchased_plan - 1];
          }
        }
      );


    this.authService.isLoggedIn()
      .subscribe(
        res => {
          if (res) {
            this.isLoggedIn = res;

            if (localStorage.getItem('user') && this.checkIfValidJSON(localStorage.getItem('user'))) {
              this.user = JSON.parse(localStorage.getItem('user'));
            }
          } else {
            this.isLoggedIn = false;
          }
        }
      );

  }

  public logOut() {
    localStorage.clear();
    this.authService.authSubject.next(false);
    this.authService.userSubject.next(null);
    this.router.navigate(['/access/login']);
  }

  public goToProfile() {
    this.router.navigate(['/profile']);
  }

  private checkIfValidJSON(text) {

    if (!text) {
      return false;
    }
    try {
      JSON.parse(text);
    } catch (e) {
      return false;
    }
    return true;
  }


}
