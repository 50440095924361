import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './core/authentication/authentication.service';
import {NavigationEnd, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isOutlined = false as boolean;
  public showForm = false;
  public errorMessage = '';
  public form: FormGroup;
  public showCookiesPopup = true;
  private emailRegex = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);


  constructor(private authService: AuthenticationService,
              private fb: FormBuilder,
              private router: Router) {
    this.form = this.fb.group({
      subject: ['', Validators.required],
      email: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  ngOnInit() {
    if (localStorage.getItem('cookieSeen')) {
      this.showCookiesPopup = JSON.parse(localStorage.getItem('cookieSeen')) === 'true';
    }

    this.authService.getUserStatus()
      .subscribe(
        res => {
          this.authService.userSubject.next(res);
        },
        err => {
          localStorage.clear();
          this.authService.authSubject.next(false)
        }
      );

    this.router.events.subscribe(
      res => {
        if (res instanceof NavigationEnd) {

          if (res.url.match('/panel/tutorial')) {
            this.isOutlined = true;
          }
        }
      }
    );

  }

  public checkCookies = () => {
    this.showCookiesPopup = false;
    localStorage.setItem('cookieSeen', 'true');
  }

  public submit({value, valid}: { value: any, valid: boolean }) {
    this.errorMessage = '';
    if (valid) {

      if (!this.emailRegex.test(value.email)) {
        this.errorMessage = 'Invalid email';
      } else {
        window.location.href = 'mailto:admin@forevermemories.io'
          + '?cc='
          + '&subject=' + escape(value.subject)
          + '&body=' + escape(value.message);

        this.showForm = false;
      }
    } else {
      this.errorMessage = 'Please fill in the form';
    }
  }
}
