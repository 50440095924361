import {Endpoint} from './../shared/models/endpoint.model';

export const API = {
  LOGIN: Endpoint.create('login'), // Example. You can import these constants in a service and use the Endpoint properties {url, auth}
  REGISTER: Endpoint.create('signup'),
  PASSWORD_FORGOTTEN: Endpoint.create('password-forgotten'),
  RESET_PASSWORD: Endpoint.create('reset-password'),
  PASSWORD_CHANGE: Endpoint.create('password-change'),
  UPDATE_PROFILE: Endpoint.create('update-profile'),
  USER_STATUS: Endpoint.create('user-status'),
  CODE_VERIFICATION: Endpoint.create('signup/code-verification'),
  BRAINTREE_GET_TOKEN: Endpoint.create('braintree-token'),
  BRAINTREE_SALE: Endpoint.create('braintree-sale'),
  STRIPE_SALE: Endpoint.create('stripe-sale'),
  SEND_MESSAGE: Endpoint.create('message'),
  GET_VIDEO: Endpoint.create('get-video'),
  CONTACTS: Endpoint.create('contacts'),
  SEND_CONTACTS: Endpoint.create('send-contacts'),
  DELETE_CONTACT: Endpoint.create('delete-contact'),
  DELETE_CONTACTS: Endpoint.create('delete-contacts'),
  PAYMENT_PLAN: Endpoint.create('payment-plans'),
  USER_PROFILE: Endpoint.create('user-profile'),
  DELETE_ACCOUNT: Endpoint.create('delete-account'),
  LOAD_EXECUTOR: Endpoint.create('load-executor'),
  CONFIRM_EXECUTOR: Endpoint.create('confirm-executor'),
  ACTIVATION_PROCESS: Endpoint.create('activation-process'),
  DEATH_CONFIRMATION: Endpoint.create('death-confirmation'),
  USER_PLAN: Endpoint.create('user-plan'),
  SAVE_EXECUTOR: Endpoint.create('save-executor'),
  REJECT_EXECUTOR: Endpoint.create('reject-executor'),
  UPLOAD_VIDEO: Endpoint.create('upload-video'),
  GOOGLE_URL: Endpoint.create('google-url'),
  GOOGLE_USER_LIST: Endpoint.create('google-user-list'),
  USER_MESSAGE: Endpoint.create('user-message'),
  USER_VIDEO: Endpoint.create('user-video'),
  EXECUTOR_REJECTION: Endpoint.create('executor-rejection'),
  ACTIVATE_AND_CHANGE_PASSWORD: Endpoint.create('activate-change-password'),
  STORE_CRON_MESSAGE: Endpoint.create('cron-message'),
  GET_CRON_MESSAGE: Endpoint.create('get-cron-messages'),
  UPLOAD_CSV_FILE: Endpoint.create('groupusers/add'),
  DELETE_CRON_MESSAGE: Endpoint.create('delete-cron-message'),
  EDIT_CRON_MESSAGE:Endpoint.create('edit-cron-message'),
  UPDATE_CRON_MESSAGE:Endpoint.create('update-cron-message')
};
