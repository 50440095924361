import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormControl} from '@angular/forms';
import {PreviewService} from '../../shared/services/preview.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {


  public videoControl: FormControl = new FormControl();
  public message = '' as string;
  public loadingVideo = false;


  constructor(private activatedRoute: ActivatedRoute,
              private previewService: PreviewService) {
  }

  ngOnInit() {
    this.activatedRoute.params
      .subscribe(
        res => {
          if (res && res.id) {
            this.previewService.getMessage(res.id)
              .subscribe(
                (data: any) => {

                  if (data && data.message) {
                    this.message = data.message;
                  }
                }
              );

            this.previewService.getVideo(res.id)
              .subscribe(
                (data: any) => {
                  if (data) {
                    if (data && data.type === 'application/json') {
                    } else {
                      this.readFile(data);
                    }
                  } else {
                    this.loadingVideo = false;
                    this.videoControl.setValue(null);
                  }
                }
              );
          }
        }
      );
  }


  readFile(image: Blob) {
    const reader = new FileReader();

    reader.onloadend = (ev: any) => {
      this.loadingVideo = false;
      this.videoControl.setValue(ev.target.result);
    };

    reader.readAsDataURL(image);
  }

}
