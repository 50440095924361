import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  field: FormControl = new FormControl('', Validators.required);

  @Input()
  id = '' as string;

  @Input()
  placeholder = '' as string;

  @Input()
  label = '' as string;

  @Input()
  type = 'text' as string;

  @Input()
  initialValue = '' as string;

  @Input()
  extraCSSClass = '' as string;

  @Input()
  isOutline = false as boolean;

  @Output()
  changeEvent: EventEmitter<any> = new EventEmitter<any>();


  @Output()
  keyUpEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {

    if (this.initialValue) {
      this.field.setValue(this.initialValue);
    }
  }

  public change() {
    this.changeEvent.emit(this.field.value);
  }

  public keyUp() {
    this.keyUpEvent.emit(this.field.value);
  }

}
