import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeadingTextComponent} from './components/heading-text/heading-text.component';
import { HeaderComponent } from './components/header/header.component';
import {RouterModule} from '@angular/router';
import { ButtonComponent } from './components/button/button.component';
import { InputComponent } from './components/input/input.component';
import {ReactiveFormsModule} from '@angular/forms';
import { FilterListPipe } from './pipes/filter-list.pipe';
import { ConfirmComponent } from './components/confirm/confirm.component';
import {MessageService} from './services/message.service';
import {PreviewService} from './services/preview.service';
import { NumberTextComponent } from './components/number-text/number-text.component';
import { StepsComponent } from './components/steps/steps.component';
import { FaqViewComponent } from './components/faq-view/faq-view.component';

@NgModule({
  declarations: [
    HeadingTextComponent,
    HeaderComponent,
    ButtonComponent,
    InputComponent,
    FilterListPipe,
    ConfirmComponent,
    NumberTextComponent,
    StepsComponent,
    FaqViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [
    HeadingTextComponent,
    HeaderComponent,
    ButtonComponent,
    InputComponent,
    FilterListPipe,
    ConfirmComponent,
    NumberTextComponent,
    StepsComponent,
    FaqViewComponent
  ],
  providers: [MessageService, PreviewService]
})
export class SharedModule { }
