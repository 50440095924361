import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class ApiDelegate {

  constructor(private httpClient: HttpClient) {
  }

  get<T>(url: string, auth = 'Basic'): Observable<T> {
    return this.httpClient.get<T>(url, this.getAuthHeaders(auth));
  }

  post<T>(url: string, body: any, auth = 'Basic'): Observable<T> {
        
    return this.httpClient.post<T>(url, body, this.getAuthHeaders(auth));
  }

  put<T>(url: string, body: any, auth = 'Basic') {
    return this.httpClient.put<T>(url, body, this.getAuthHeaders(auth));
  }

  delete<T>(url: string, auth) {
    return this.httpClient.delete<T>(url, this.getAuthHeaders(auth));
  }

  upload<T>(url: string, body: any, auth = 'Basic'): Observable<T> {
    return this.httpClient.post<T>(url, body, {
      headers: {Authorization: auth, timeout: '180000'},
      observe: 'body',
      reportProgress: true
    });
  }

  getFile<T>(url: string, auth = 'Basic'): Observable<T> {
    return this.httpClient.get<T>(url, {
      headers: {Authorization: auth},
      responseType: 'blob' as 'json',
      reportProgress: true
    });
  }

  getAuthHeaders(auth: string) {
    return {headers: {Authorization: auth, 'Content-Type': ('application/json')}};
  }

}
