import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RegisterModel} from '../../../shared/models/register.model';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../core/authentication/authentication.service';

declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public form: FormGroup;
  public errorMessage = '' as string;
  public openPopup = false as boolean;
  public acceptTerms = new FormControl(false);

  constructor(private fb: FormBuilder,
              private authService: AuthenticationService,
              private router: Router) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      password: ['', Validators.required]
    });
  }


  ngOnInit() {
  }

  public submit({value, valid}: { value: RegisterModel, valid: boolean }) {
    this.errorMessage = '';
    this.authService.dataSubject.next({type: 'none', data: null});
    if (valid && this.acceptTerms.value) {
      this.authService.register(value)
        .subscribe(
          res => {
            this.openPopup = true;

            setTimeout(_ => {
              this.openPopup = false;
              this.router.navigate(['/home']);
            }, 2000)
          },
          err => {

            if (err && err.error && err.error.error) {
              this.errorMessage = err.error.error;
            } else {
              this.errorMessage = 'Something went wrong, please try again.';
            }
          }
        );
    } else {
      this.errorMessage = 'Please fill in the form';
    }

  }
}
