import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../core/authentication/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {RegisterModel} from "../../shared/models/register.model";

declare var $: any;

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.css']
})
export class AccountActivationComponent implements OnInit {

  public form: FormGroup;
  public errorMessage = '' as string;
  public openPopup = false as boolean;
  public acceptTerms = new FormControl(false);
  public token: any;

  constructor(private fb: FormBuilder,
              private authService: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.token && params.token !== '') {
        this.token = params.token;
      } else {
        this.router.navigate(['/']);
      }
    });

    this.form = this.fb.group({
      old_password: ['', Validators.required],
      new_password: ['', Validators.required],
      confirm_new_password: ['', Validators.required],
      token: [this.token, Validators.required]
    });
  }


  ngOnInit() {
  }

  public submit({value, valid}: { value: RegisterModel, valid: boolean }) {
    this.errorMessage = '';
    this.authService.dataSubject.next({type: 'none', data: null});
    if (valid && this.acceptTerms.value) {
      this.authService.activateAndChangePassword(value)
        .subscribe(
          res => {
            this.openPopup = true;

            setTimeout(_ => {
              this.openPopup = false;
              this.router.navigate(['/home']);
            }, 2000);
          },
          err => {
            console.log(err.error);
            console.log(err.message);
            if (err) {
              this.errorMessage = '';
              if (err.error.old_password) {
                this.errorMessage += err.error.old_password[0] + '<br/>';
              }
              if (err.error.new_password) {
                this.errorMessage += err.error.new_password[0] + '<br/>';
              }
              if (err.error.confirm_new_password) {
                this.errorMessage += err.error.confirm_new_password[0] + '<br/>';
              }
              if (err.error.token) {
                this.errorMessage += err.error.token[0] + '<br/>';
              }
              if (err.error.result == "showError") {
                this.errorMessage += err.error.message + '<br/>';
              }
            } else {
              this.errorMessage = 'Something went wrong, please try again.';
            }
          }
        );
    } else {
      this.errorMessage = 'Please fill in the form';
    }

  }

}
