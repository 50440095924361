import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-number-text',
  templateUrl: './number-text.component.html',
  styleUrls: ['./number-text.component.scss']
})
export class NumberTextComponent implements OnInit {


  @Input()
  number = '' as string;

  @Input()
  text = '' as string;

  constructor() {
  }

  ngOnInit() {
  }

}
