import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input()
  public text = '' as string;

  @Input()
  public extraCSSClass = '' as string;

  @Input()
  public isOutline = false as boolean;

  @Input()
  public type = 'submit' as string;

  @Input()
  public disabled = false as boolean;

  @Input()
  public hasArrow = true as boolean;

  @Input()
  public hasBackArrow = false as boolean;

  @Input()
  public size = 'lg' as string;

  @Output()
  public clickEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  public click() {
    this.clickEvent.emit();
  }

}
