import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterList'
})
export class FilterListPipe implements PipeTransform {
  transform(list: any, key: string, search?: string): any {
    if (search) {
      return list.filter(item => {
        return item[key].toLowerCase().includes(search);
      });
    } else {
      return list;
    }
  }

}
