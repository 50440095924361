import {Component, OnInit} from '@angular/core';
import {MessageService} from '../../shared/services/message.service';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-bulk-users',
  templateUrl: './bulk-users.component.html',
  styleUrls: ['./bulk-users.component.scss']
})
export class BulkUsersComponent implements OnInit {

  fileToUpload: File = null;
  fileName: string;
  loading: boolean = true;
  message: string = '';

  constructor(private messageService: MessageService, private  activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (!params.token || params.token !== 'token-bulk-upload-1238sadnasnjsckmzvjn!') {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnInit() {
  }

  public onClick() {
    const element: HTMLElement = document.getElementById('fileUpload') as HTMLElement;
    element.click();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.fileName = files.item(0).name;

    const formData: FormData = new FormData();
    formData.append('file', this.fileToUpload)
    this.messageService.uploadCsvFile(formData).subscribe(
      res => {
        this.message = 'File uploaded';
      },
      err => {
        this.message = 'Could not upload the file';
      }
    )
  }
}
