import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccessService} from '../_services/access.service';

declare var $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public form: FormGroup;
  public errorMessage = '' as string;
  public showPopup = false as boolean;
  public message = '';


  constructor(private fb: FormBuilder, private accessService: AccessService) {
    this.form = this.fb.group({
      email: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  submit({value, valid}: { value: any, valid: boolean }) {
    this.errorMessage = '';
    if (valid) {
      this.accessService.getResetLink(value)
        .subscribe(
          res => {
            this.openPopup('An email has just been sent to reset your password');
          },
          err => {

            if (err && err.data && err.data.error) {
              this.errorMessage = err.data.error.error;
            } else {
              this.errorMessage = 'Something went wrong, please try again.';
            }
          }
        );
    } else {
      this.errorMessage = 'Please fill in the form';
    }
  }

  public openPopup(message) {
    this.showPopup = true;
    this.message = message;
  }

}
