import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccessService} from '../_services/access.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../core/authentication/authentication.service';

declare var $: any;

@Component({
  selector: 'app-reset-temp-password',
  templateUrl: './reset-temp-password.component.html',
  styleUrls: ['./reset-temp-password.component.scss']
})
export class ResetTempPasswordComponent implements OnInit {

  public form: FormGroup;
  public errorMessage = '';
  public showPopup = false as boolean;
  public message = '';

  constructor(private fb: FormBuilder,
              private router: Router,
              private authService: AuthenticationService,
              private accessService: AccessService) {
    this.form = this.fb.group({
      email: ['', Validators.required],
      old_password: ['', Validators.required],
      new_password: ['', Validators.required],
      repeat_new_password: ['', Validators.required]
    });
  }

  ngOnInit() {
  }


  public submit({value, valid}: { value: any, valid: boolean }) {
    this.errorMessage = '';
    if (valid) {
      if (value.new_password !== value.repeat_new_password) {
        this.errorMessage = 'Repeat password does not match';
      } else {
        const body = {
          email: value.email,
          old_password: value.old_password,
          new_password: value.new_password
        };
        this.accessService.resetTempPassword(body)
          .subscribe(
            res => {
              this.openPopup('Password has changed');
            },
            err => {
              if (err && err.error.error) {
                this.errorMessage = err.error.error;
              } else {
                this.errorMessage = 'Something went wrong, please try again';
              }
            }
          );
      }
    } else {
      this.errorMessage = 'Please fill in the form';
    }
  }

  public openPopup(message) {
    this.message = message;
    this.showPopup = true;
    setTimeout(_ => {
      this.showPopup = false;
      this.authService.login({email: this.form.value.email, password: this.form.value.new_password});
    }, 3000);
  }


}
