import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from "../../../core/authentication/authentication.service";

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {
  @Input()
  public currentStep = '' as string;
  userData: any;

  constructor( private authService: AuthenticationService,) {
  }

  ngOnInit() {
    this.authService.getUserData()
      .subscribe(
        res => {
          this.userData = res;
        });
  }

}
