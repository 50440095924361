import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginModel} from '../../../shared/models/login.model';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form: FormGroup;
  public errorMessage = '' as string;
  public message = '';
  public showPopup = false as boolean;


  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,

              private authService: AuthenticationService) {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }


  ngOnInit() {

    this.activatedRoute.queryParams
      .subscribe(
        param => {
          if (param && param['verify']) {
            this.authService.checkVerification({code: param['verify']})
              .subscribe(
                res => {
                  this.openPopup(res.message);
                },
                (err: any) => {

                  if(err && err.error && err.error.message) {
                    this.openPopup(err.error.message);
                  } else {
                    this.openPopup('Code is not correct!');
                  }
                }
              );
          } else if (param['executor-rejection']) {

          }
        }
      );

    this.authService.dataObject
      .subscribe(
        res => {
          if (res && res.type === 'login' && res.data.error) {
            this.errorMessage = res.data.error.error;
          }
        }
      );
  }

  submit({value, valid}: { value: LoginModel, valid: boolean }) {
    this.authService.dataSubject.next({type: 'none', data: null});
    this.errorMessage = '';
    if (valid) {
      this.authService.login(value);
    } else {
      this.errorMessage = 'Please fill in the form';
    }
  }

  public openPopup(message) {
    this.message = message;
    this.showPopup = true;
    setTimeout(_ => {
      this.showPopup = false;
    }, 2000);
  }

}
