import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DelegateService} from '../../delegate/_services/delegate.service';
import {AccessService} from '../_services/access.service';

declare var $: any;

@Component({
  selector: 'app-executor-rejection',
  templateUrl: './executor-rejection.component.html',
  styleUrls: ['./executor-rejection.component.scss']
})
export class ExecutorRejectionComponent implements OnInit {


  public message = '';
  public title = '';
  public showPopup = false as boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private accessService: AccessService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .subscribe(
        params => {
          if (params && params['code']) {
            this.accessService.rejectExecution(`?code=${params['code']}`)
              .subscribe(
                (res: any) => {
                  this.title = (res && res['user_name']) ? `We sent your choice to ${res.user_name}. Thanks for your answer.` : `Reject executor role`;
                  this.openPopup('Successfully rejected');
                },
                err => {
                  this.openPopup('Code is not correct');
                }
              );
          }
        }
      );
  }

  public openPopup(message) {
    this.message = message;
    this.showPopup = true;
    setTimeout(_ => {
      this.showPopup = false;
      this.router.navigate(['/home']);
    }, 3000);
  }


}
